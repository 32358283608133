import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/misc/LoginView.vue'
import JobsView from '@/views/jobs/JobsView.vue'
import JobDetails from '@/views/jobs/JobDetails.vue'
import NewJob from '@/views/jobs/NewJob.vue'
import UserList from '@/views/users/UserList.vue'
import NewUser from '@/views/users/NewUser.vue'
import JobTimeline from '@/views/jobs/JobTimeline.vue'
import ProfileView from '@/views/user/ProfileView.vue'
import DashboardLayoutView from '@/views/layouts/DashboardLayoutView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardLayoutView,
    children : [
      {
        path : 'overview',
        name : 'Overview',
        component : JobsView
      },
      {
        path : 'jobs',
        name : 'Jobs',
        component : JobsView
      },
      {
        path : 'jobs/details/:id',
        name : 'JobDetails',
        component : JobDetails
      },
      {
        path : 'jobs/add',
        name : 'NewJob',
        component : NewJob
      },
      {
        path : 'jobs/timeline/:id',
        name : 'JobTimeline',
        component : JobTimeline
      },
      {
        path : 'users',
        name : 'UserList',
        component : UserList
      },
      {
        path : 'profile',
        name : 'Profile',
        component : ProfileView
      },
      {
        path : 'users/add',
        name : 'NewUser',
        component : NewUser
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
