import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VNavigationDrawer,{attrs:{"app":"","color":"black","src":require("@/assets/sidebar.png"),"dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"logo-text white--text text-center mt-3"},[_c('h3',[_vm._v("LUNTIAN")]),_c('small',[_vm._v("DESIGN & SOLUTIONS")])]),_c('div',{staticClass:"text-center mb-5 mt-5"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"","height":"70"}})]),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItemGroup,{model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._l((_vm.menus),function(m,i){return _c(VListItem,{key:i,attrs:{"link":"","to":{name : m.link}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(m.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(m.text))])],1)],1)}),_c(VListItem,{on:{"click":function($event){return _vm.logout()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-power")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],2)],1)],1),_c(VAppBar,{staticClass:"darken-4",attrs:{"app":"","color":"indigo","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v("Application")]),_c(VSpacer),_c(VAvatar,{staticClass:"darken-2 pointer",attrs:{"color":"indigo","size":"40"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$store.getters.getUser.name.substring(0, 1)))])])],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }