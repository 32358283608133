<template>
    <v-bottom-sheet v-model="show" @click:outside="close">
        <v-sheet
            class="text-center v-scroll"
            height="300px"
        >
            <v-row no-gutters>
                <v-col cols="12" md="5" class="indigo darken-2" style="height:300px;">
                    <v-list color="indigo" dark subheader class="text-left darken-2" dense>
                        <v-subheader class="text-center">Job Details</v-subheader>
                        <v-list-item>
                            <v-list-item-content>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-list-item-subtitle>Ref #</v-list-item-subtitle>
                                        <v-list-item-title>{{ details.lbs_ref_no }}</v-list-item-title>
                                    </v-col>
                                    <v-col>
                                        <v-list-item-subtitle>Job Status</v-list-item-subtitle>
                                        <v-list-item-title>{{ details.job_status }}</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-list-item-subtitle>Job Type</v-list-item-subtitle>
                                        <v-list-item-title>{{ getJobType(details.job_type_id) }}</v-list-item-title>
                                    </v-col>
                                    <v-col>
                                        <v-list-item-subtitle>Priority</v-list-item-subtitle>
                                        <v-list-item-title>{{ details.priority }}</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Address</v-list-item-subtitle>
                                <v-list-item-title>{{ details.job_address }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-content>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-list-item-subtitle>Submitted Date</v-list-item-subtitle>
                                        <v-list-item-title>{{ details.date_submitted }}</v-list-item-title>
                                    </v-col>
                                    <v-col>
                                        <v-list-item-subtitle>Submitted By</v-list-item-subtitle>
                                        <v-list-item-title>{{ details.client ? details.client.name : '' }}</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="details.staff"></v-divider>
                        <v-list-item v-if="details.staff">
                            <v-list-item-content>
                                <v-list-item-subtitle>Assigned Staff</v-list-item-subtitle>
                                <v-list-item-title>{{ details.staff.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="3" class="v-scroll" style="max-height:300px;">
                    <div class="file-cont">
                        <div class="header">Plans</div>
                        <div class="files">
                            <ul>
                                <li v-for="p in details.plans" v-bind:key="p.id">
                                    <a target="_blank" class="link" :href="host_url + '/uploads/plans/' + p.job_id + '/' + p.filename">
                                        <v-icon color="indigo">mdi-file-document</v-icon> {{ p.filename }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="file-cont">
                        <div class="header">Documents</div>
                        <div class="files">
                            <ul>
                                <li v-for="p in details.documents" v-bind:key="p.id">
                                    <a target="_blank" class="link" :href="host_url + '/uploads/documents/' + p.job_id + '/' + p.filename">
                                        <v-icon color="indigo">mdi-file-document</v-icon> {{ p.filename }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="file-cont" v-if="details.job_status == 'for approval' || details.job_status == 'completed'">
                        <div class="header">Frates</div>
                        <div class="files">
                            <ul>
                                <li v-for="p in details.frates" v-bind:key="p.id">
                                    <a target="_blank" class="link" :href="host_url + '/uploads/documents/' + p.job_id + '/' + p.filename">
                                        <v-icon color="indigo">mdi-file-document</v-icon> {{ p.filename }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="file-cont" v-if="details.job_status == 'for approval' || details.job_status == 'completed'">
                        <div class="header">DB Files</div>
                        <div class="files">
                            <ul>
                                <li v-for="p in details.dbs" v-bind:key="p.id">
                                    <a target="_blank" class="link" :href="host_url + '/uploads/documents/' + p.job_id + '/' + p.filename">
                                        <v-icon color="indigo">mdi-file-document</v-icon> {{ p.filename }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="4" class="vh-100 v-scroll" style="max-height:300px;">
                    <v-list subheader class="text-left darken-3" dense>
                        <v-subheader class="text-center">Timeline</v-subheader>
                        <v-list-item v-for="t in timelines" v-bind:key="t.id">
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ t.timestamp }}</v-list-item-subtitle>
                                <v-list-item-title>{{ t.description }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-sheet>
    </v-bottom-sheet>
</template>
<style scoped>
    .vh-100 {
        height : 100vh;
    }
    .v-scroll {
        overflow-y: auto;
    }
    .file-cont .header{
        font-size: 0.75rem;
        height: 40px;
        padding: 0 8px;
        display: flex;
        align-items: center;
    }
    .file-cont .file-icon {
        font-size : 35px;
        color: #303f9f;
    }
    .file-cont .files ul li {
        list-style: none;
        padding : 5px 10px;
        color: #303f9f;
        font-size: 14px;
        text-align: left;
    }
    .text-sm {
        font-size : 13px;
    }
    .link {
        text-decoration: none;
    }
</style>
<script>
    import { globalMixins } from '@/mixins/globalMixins';
    export default {
        name : 'BottomSheetJobDetails',
        props : ['details','show', 'timelines'],
        mixins : [globalMixins],
        data() {
            return {
                host_url : process.env.VUE_APP_HOST_URL
            }
        },
        methods : {
            close() {
                this.$emit('update:show', false);
            }
        }
    }
</script>